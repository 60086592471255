import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import { postNotData } from '../../../../redux/actions/mineWarehouse';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy, {axiosProxyCatchPost} from '../../../../axiosProxy';
import { fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';

const RollbackPayment = ({ unDisplay, query}) => {
    const dispatch = useDispatch()
    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)
    const [loading, setLoading] = useState(false)

    const postData = async() =>{
        setLoading(true)

        axiosProxyCatchPost({
            url: '/note/Client/delete-payment',
            data: {id: leaderHomeCheckClient.id},                                
            resolved: (data)=>{
                if(data){
                    dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                }
                setLoading(false)
                unDisplay()
            },
            rejected: (error)=>{                
                // if(error && error.data && typeof error.data === 'string') setError(error.data)
                // else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                // else setError('Что-то пошло не так*')     
                console.log(error)
            }
        })
    }

	return (
        <div className="leader__popup-container confirmation-payment">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-subtitle'>{leaderHomeCheckClient.clientName}</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-confirmation-title'>Вы дествительно хотите отакатить последний платеж клиента "{leaderHomeCheckClient.clientName}" ?</div>
            <div className='d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Отменить</div>
                <div className={`leader__popup-button fifty-button ${loading? 'none-click': ''}`} onClick={postData}>Откатить</div>
            </div>
        </div>
	)
}
export default RollbackPayment;
