import axios from "axios";
import { logout } from "./redux/store";

// const baseUrl = 'http://localhost:5145'
const baseUrl = "https://localhost:7194";

const axiosProxy = axios.create({
  //baseURL: baseUrl? baseUrl: '',
  // baseURL: baseUrl ? baseUrl : 'https://localhost:5155',
  baseURL: process.env.REACT_APP_SERVER ? "" : baseUrl,
});

axiosProxy.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
axiosProxy.defaults.headers.get['Access-Control-Allow-Methods'] = '*';
axiosProxy.defaults.headers.get['Access-Control-Allow-Headers'] = "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'";
if (localStorage.getItem('save-autorize') === 'true' && localStorage.getItem('AthorizationUserData') || sessionStorage.getItem('AthorizationUserData')) axiosProxy.defaults.headers.get['Authorization'] = `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`;


axiosProxy.interceptors.response.use(response => {
  // console.log(response);
  let result = (localStorage.getItem('save-autorize')  === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')): JSON.parse(sessionStorage.getItem('AthorizationUserData'))
  if(result && !result.role) logout()

      
  if (response && response.status && response.status === 401) {
      logoutFunc()
  } else {    
      return response;
  }
}, error => {
  console.log(error);

  if (error && error.response && error.response.status && error.response.status === 401) {
      logoutFunc()
  }
  else if (error && error.response && error.response.status && error.response.status !== 500) {
      return error;
  }
  else if (!(error && error.response && error.response.status)) {
      logoutFunc()
  }
  else return error

});

const logoutFunc = () => {
  logout()
  localStorage.setItem('authorize', 'false')
  localStorage.setItem('AthorizationUserData', JSON.stringify({ name: '', token: '' }))
  localStorage.setItem('token', '')
  sessionStorage.setItem('authorize', 'false')
  sessionStorage.setItem('AthorizationUserData', JSON.stringify({ name: '', token: '' }))
  sessionStorage.setItem('token', '')
}



export const axiosProxyCatchPost = async ({ url, data, resolved, rejected, isFormData = false, isNotAuthorize = false }) => {
  try {
      const response = await axiosProxy.post(url, data,
          {
              headers: {
                  ...(!isFormData ? {
                      "Accept": 'application/json',
                      'Content-Type': 'application/json',
                  }
                      : {
                          'Content-Type': 'multipart/form-data',
                      }),
                  ...{
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Methods': '*',
                      'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                  },
                  ...(isNotAuthorize ? {}
                      : {
                          'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                      })
              },
          })
      if (response && response.status && response.status < 400) {
          // console.log(response);
          resolved(response.data)
          if (response.data && response.data.token) axiosProxy.defaults.headers.get['Authorization'] = `Bearer ${response.data.token}`;
      }
      else {
          if (response && response.response) rejected(response.response)
      }
  } catch (error) {
      rejected('')
  }
}

export const axiosProxyCatchGet = async ({ url, resolved, rejected, isNotAuthorize = false }) => {
  try {
      const response = await axiosProxy.get(url,
          {
              headers: {
                  ...{
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Methods': '*',
                      'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                  },
                  ...(isNotAuthorize ? {}
                      : {
                          'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                      })
              },
          }
      )

      if (response && response.status && response.status === 200) {
          resolved(response)
      }
      else {
          rejected()
      }
  } catch (error) {
      rejected(error)
  }
}

export default axiosProxy;
