import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setAdminHomeLeadersAdd, setAdminHomeLeadersDelete, setAdminHomeLeadersEdit } from '../../../../redux/admin/users/clientsSlice';
import { setLeaderHomeClientsAdd } from '../../../../redux/home/clients/clientsSlice';
// import { postNotData } from '../../../../redux/actions/mineWarehouse';

import MaskedInput from '../../../MaskedInput/MaskedInput';
import { selectAdminHomeLeaders } from '../../../../redux/admin/users/selectors';
import axiosProxy, { axiosProxyCatchPost } from './../../../../axiosProxy';

const AddLeader = ({type, popupBack, unDisplay}) => {
    const dispatch = useDispatch()

    const [error, setError] = useState('')
    const {adminHomeCheckLeader} = useSelector(selectAdminHomeLeaders)

    const today = new Date()

    const [inform, setInform] = useState((type === 'EditLeader')? adminHomeCheckLeader:{
        id: 0,
        name: '',
        phone: '',   
        login: '',
        password: '',   
        paymentDate: new Date().toLocaleDateString(),
        monthsCount: '',
    })

    useEffect(()=>{
        setInform((type === 'EditLeader')? adminHomeCheckLeader:{
            id: 0,
            name: '',
            phone: '',   
            login: '',
            password: '',   
            paymentDate: new Date().toLocaleDateString(),
            monthsCount: '',
        })
    }, [popupBack, type])

    const postData = async(event) =>{
        event.preventDefault()

        if(type === 'EditLeader'){
            dispatch(setAdminHomeLeadersEdit(inform))
            unDisplay()
        }
        else{
            let result = {
                name: inform.name, 
                phone: inform.phone, 
                login: inform.login,
                password: inform.password,
                now: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(today),
                payment: inform.monthsCount               
            };

            axiosProxyCatchPost({
                url: 'note/Admin/create-user',
                data: result,
                resolved: (data)=>{                
                    dispatch(setAdminHomeLeadersAdd({...inform, id: data.id}))            
                    unDisplay()           
                },
                rejected: (error)=>{                
                    // if(error && error.data) setError(error.data)
                    // else setError('Что-то пошло не так*')     
                    console.log(error)
                }
            })
        }
        
    }

    const deleteLeader = async () =>{
        axiosProxyCatchPost({
            url: 'note/Admin/delete-user',
            data: {id: adminHomeCheckLeader.id},
            resolved: (data)=>{                
                dispatch(setAdminHomeLeadersDelete(inform.id))
                unDisplay()           
            },
            rejected: (error)=>{                
                // if(error && error.data) setError(error.data)
                // else setError('Что-то пошло не так*')     
                console.log(error)
            }
        })
    }

	return (
        <div className="leader__popup-container scroll-active">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title'>{(type === 'EditLeader')?'Редактирование пользователя':'Добавление пользователя'}</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>            
            <div className='leader__popup-container-scroll'>
                <label htmlFor="" className='leader__popup-label'>ФИО</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.name} className="leader__popup-input" onChange={(e) => setInform({...inform, name: e.target.value})}/>
                <label htmlFor="" className='leader__popup-label mt-20'>Номер телефона</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="+7(___) ___-__-__"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.phone}
                    name = 'phone'
                    readOnly={false}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, phone: event.target.value})
                    }}
                />

                <label htmlFor="" className='leader__popup-label mt-20'>Логин</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.login} className="leader__popup-input" onChange={(e) => setInform({...inform, login: e.target.value})}/>

                <label htmlFor="" className='leader__popup-label mt-20'>Пароль</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.password} className="leader__popup-input" onChange={(e) => setInform({...inform, password: e.target.value})}/>

                <div className='d-flex justify-between'>
                    <div className='leader__popup-input-fifty'>
                        <label htmlFor="" className='leader__popup-label mt-20'>Дата платежа</label>
                        <MaskedInput
                            className={`leader__popup-input`}
                            mask="__.__.____"
                            replacement={{_ : /[0-9]/}}
                            showMask={true}
                            defaultValue={inform.paymentDate}
                            name = 'paymentDate'
                            readOnly={true}
                            placeholder=''
                            onChange={(event)=>{
                                setInform({...inform, paymentDate: event.target.value})
                            }}
                        />
                        {/* <div className={`leader__popup-input`}>{inform.paymentDate}</div> */}
                    </div>
                    <div className='leader__popup-input-fifty'>
                        <label htmlFor="" className='leader__popup-label mt-20'>Месяцев оплачено</label>
                        <input name='mounthsCount' autoComplete="off" type="number" placeholder='' value={inform.monthsCount} className="leader__popup-input" 
                        onChange={(e) => setInform({...inform, monthsCount: e.target.value})}/>
                    </div>

                </div>
            </div>
            <div className='d-flex justify-between mt-20'>
                {
                    (type === 'EditLeader')? 
                    <div className='leader__popup-button fifty-button delete' onClick={deleteLeader}>Удалить</div>
                    :<div className='leader__popup-button fifty-button' onClick={unDisplay}>Отменить</div>
                }
                <div className='leader__popup-button fifty-button' onClick={postData}>{(type === 'EditLeader')?'Сохранить':'Добавить'}</div>                
            </div>
        </div>
	)
}
export default AddLeader;