import React, { useState } from 'react';


// import Logo from ''
import Login from '../../img/login-icon.svg'
// import Password from '../../img/password-icon.svg'
import Password from '../../img/eye-icon.svg'
import PasswordActive from '../../img/eye-active-icon.svg'

import './index.scss'
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/authorization/authorization';
import axiosProxy, { axiosProxyCatchPost } from './../../axiosProxy';


const Autorization = () =>{
    const dispatch = useDispatch()
    const [inform, setInform] = useState({
        login: '',
        password: '',
        save: true
    })

    const [error, setError] = useState('')
    const [displayPass, setDisplayPass] = useState(false)

    const autorize = async (event) => {
        event.preventDefault()
        
        axiosProxyCatchPost({
            url: 'note/Account/login',
            data: { email: inform.login, password: inform.password, rememberMe: inform.save },
            resolved: (data)=>{                
                if(!inform.save){
                    sessionStorage.setItem('AthorizationUserData', JSON.stringify({id: data.id, name: '', token: data.token, role: data.role}))
                }
                else{
                    localStorage.setItem('save-autorize', true)
                    localStorage.setItem('AthorizationUserData', JSON.stringify({id: data.id, name: '', token: data.token, role: data.role}))
                }
                dispatch(setUserData({id: data.id, name: '', token: data.token, role: data.role}))                
            },
            rejected: (error)=>{                
                if(error && error.data && typeof error.data === 'string') setError(error.data)
                else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')                        
            }
        })
    }

	return (
        <div className={`autorization d-flex justify-center align-center`}>
            <form className="autorization__content" onSubmit={(event)=>autorize(event)}>
                {/* <img src={''} alt="" className="autorization__logo" /> */}
                {/* <div className="autorization__logo" /> */}
                <div className="autorization__content-form">
                    <div className="autorization__content-form-title">Авторизация</div>
                    <label className="autorization__content-form-lable">Логин</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between">
                        <input  type="text" placeholder="Введите свой логин или e-mail" className="autorization__content-form-field-input" onInput={(event)=>{
                            setInform({...inform, login: event.target.value})
                        }}/>
                        <img src={Login} alt="" className="autorization__content-form-field-icon login"/>
                    </div>
                    <label className="autorization__content-form-lable">Пароль</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between">
                        <input type={(displayPass?'text':'password')} placeholder="Введите свой пароль" className="autorization__content-form-field-input" onInput={(event)=>{
                            setInform({...inform, password: event.target.value})
                        }}/>
                        <img src={(displayPass? PasswordActive: Password)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                        <span></span>
                    </div>
                    <input type="checkbox" name="save_autorize" defaultChecked value="Сохранить меня" id="save_autorize" className="autorization__content-form-checkbox" onClick={() => {
                        setInform({...inform, save: !inform.save})
                        localStorage.setItem('save-autorize', !inform.save)
                    }}/>
                    <label className="autorization__content-form-checkbox-label" htmlFor="save_autorize">Сохранить меня</label>
                    <div className='popup__error'>{error}</div>
                    <button type="submit" className="autorization__content-form-btn">Войти</button>
                </div>
            </form>
        </div>
	)
}

export default Autorization;