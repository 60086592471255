import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setLeaderHomeCheckClientDataFiles, setLeaderHomeClientsAdd } from '../../../../redux/home/clients/clientsSlice';
import { CalculatorBottom, RangeElem } from '../Calculator/Calculator';
// import { postNotData } from '../../../../redux/actions/mineWarehouse';

import MaskedInput from './../../../MaskedInput/MaskedInput';
import axiosProxy, { axiosProxyCatchPost } from './../../../../axiosProxy';

import { fetchLeaderHomeClientsData } from './../../../../redux/home/clients/asyncActions';
import { choiceUserFilesFunc, deleteUserFilesFunc, getDateFormatFunc } from '../../../../services/clientHome';
import { setAdminHomeCheckLeader } from '../../../../redux/admin/users/clientsSlice';

const AddClient = ({type, popupTypeEdit, popupBack, unDisplay, query}) => {
    const dispatch = useDispatch()

    const [error, setError] = useState('')
    const [dataFiles, setDataFiles] = useState([])
    const [loading, setLoading] = useState(false)


    const [inform, setInform] = useState({
        id: 0,
        clientName: '',
        product: '',
        address: '',
        clientPhone: '',   
        guarantorName: '',
        guarantorPhone: '',   
        productPrice: '',
        nextDate: new Date().toLocaleDateString(),
        installmentPeriod: '',
        interestRate: '',
        anInitialFee: '',
        dataFiles: [],
        comment: ''
    })

    useEffect(()=>{
        setInform({
            id: 0,
            clientName: '',
            product: '',
            address: '',
            clientPhone: '',   
            guarantorName: '',
            guarantorPhone: '',   
            productPrice: '',
            nextDate: new Date().toLocaleDateString(),
            installmentPeriod: '',
            interestRate: '',
            anInitialFee: '',
            dataFiles: [],
            comment: ''
        })
        setError('')
        setDataFiles([])
    }, [popupBack])

    const postData = async(event) =>{
        event.preventDefault()
        if(inform.clientName && inform.clientPhone && inform.clientPhone.indexOf('_') === -1 && inform.guarantorName && inform.guarantorPhone && inform.guarantorPhone.indexOf('_') === -1 && inform.address && inform.product && inform.installmentPeriod && inform.interestRate && inform.productPrice && inform.nextDate.indexOf('_') === -1){
            setError('')
            let result = {
                name: inform.clientName, 
                phone: inform.clientPhone, 
                guarantorName: inform.guarantorName, 
                guarantorPhone: inform.guarantorPhone,
                product: inform.product,
                period: +inform.installmentPeriod,
                percent: +inform.interestRate,
                price: +inform.productPrice,
                prePayment: +inform.anInitialFee,
                address: inform.address,
                nextDate:  new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(inform.nextDate)),
            };
            if(inform.comment) result.comment = inform.comment;

            setLoading(true)
            axiosProxyCatchPost({
                url: 'note/Client/create-client',
                data: result,
                resolved: (data)=>{   
                    dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                    unDisplay()
                    
                    if(data && dataFiles.length !== 0){
                        let formData = new FormData();
                        for(let item of dataFiles) formData.append('Files', item)
                        formData.append('Guid', data.guid)
        
                        axiosProxyCatchPost({
                            url: 'note/Client/set-files',
                            data: formData,
                            isFormData: true,
                            resolved: (data)=>{
                                setLoading(false)
                            },
                            rejected: (error)=>{                
                                if(error && error.data) setError(error.data)
                                else setError('Что-то пошло не так*')     
                                console.log(error)
                            }
                        })
                    }
                    else{
                        setLoading(false)
                    }
                },
                rejected: (error)=>{                
                    if(error && error.data && typeof error.data === 'string') setError(error.data)
                    else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')     
                    console.log(error)
                }
            })
        }
        else if(inform.clientPhone.indexOf('_') !== -1 || inform.guarantorPhone.indexOf('_') !== -1 || inform.nextDate.indexOf('_') !== -1){
            if(inform.clientPhone.indexOf('_') !== -1) setError('Неправильный формат телефона клиента "+7(999) 999-99-99"')
            else if(inform.guarantorPhone.indexOf('_') !== -1) setError('Неправильный формат телефона поручителя "+7(999) 999-99-99"')
            else if(inform.nextDate.indexOf('_') !== -1) setError('Неправильный формат даты платежа "01.01.2024"')
        }
        else setError('Необходимо заполнить все поля*')
    }

    const imageClick = (key, e) =>{
        if(!e.target.classList.contains('leader__popup-download-file-img-delete-icon')){
            // dispatch(setLeaderHomeCheckClientDataFiles({index: key, files: inform.dataFiles}))
            // popupTypeEdit('Galery')            
        }
    }

    const addImageBlock = (item, key) =>{
        return(
            <div className='leader__popup-download-file-img-container d-flex justify-center align-center edit-image' key={key} onClick={(e)=>imageClick(key, e)}>                
                <div className='leader__popup-download-file-img-delete-icon' onClick={()=>deleteUserFilesFunc(key, item, (files)=>setDataFiles(files), dataFiles, (urls)=>setInform({...inform, dataFiles: urls}), inform.dataFiles)}/>
                <img className='leader__popup-download-file-img' src={item} alt="" />
                {/* <input type="file" value={item} name='user-file-inputs' accept="" className='leader__popup-download-file-input d-none' id='leader__create-user-file-inputs' onChange={(event)=> {choiceUserFilesFunc(event, (file)=>setDataFiles([...dataFiles, file]), (url)=>setInform({...inform, dataFiles: [...inform.dataFiles, url]}))}}/> */}
            </div>
        )
    }

	return (
        <div className="leader__popup-container scroll-active">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title'>Добавление клиента</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>            
            <div className='leader__popup-container-scroll'>
                <label htmlFor="" className='leader__popup-label'>ФИО клиента</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.clientName} className="leader__popup-input" onChange={(e) => setInform({...inform, clientName: e.target.value})}/>
                
                <label htmlFor="" className='leader__popup-label mt-20'>Адрес клиента</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.address} className="leader__popup-input" onChange={(e) => setInform({...inform, address: e.target.value})}/>
                
                <label htmlFor="" className='leader__popup-label mt-20'>Номер телефона клиента</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="+7(___) ___-__-__"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.clientPhone}
                    name = 'phone'
                    readOnly={false}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, clientPhone: event.target.value})
                    }}
                />

                <label htmlFor="" className='leader__popup-label mt-20'>Комментарий</label>
                <textarea name='EnvelopeName' placeholder='' value={inform.comment} className="leader__popup-textarea" onChange={(e) => setInform({...inform, comment: e.target.value})}/>


                <label htmlFor="" className='leader__popup-label  mt-20'>Файлы</label>
                <div className='d-flex justufy-between flex-wrap leader__popup-download-file-container'>                 
                    {
                        inform.dataFiles.map(addImageBlock)
                    }

                    <input type="file" name='files' accept="" className='leader__popup-download-file-input d-none' id='leader__create-user-add-file-input' onChange={(event)=> {choiceUserFilesFunc(event, (file)=>setDataFiles([...dataFiles, file]), (url)=>setInform({...inform, dataFiles: [...inform.dataFiles, url]}))}}/>
                    <label className='leader__popup-download-file-label d-flex align-centr' htmlFor='leader__create-user-add-file-input'>
                        {/* <img className='leader__popup-download-file-label-icon' src="/img/download-file-icon.svg" alt="" />
                        <div className='leader__popup-download-file-label-title'></div> */}
                    </label>
                    
                </div>

                <label htmlFor="" className='leader__popup-label mt-20'>ФИО поручителя</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.guarantorName} className="leader__popup-input" onChange={(e) => setInform({...inform, guarantorName: e.target.value})}/>
                <label htmlFor="" className='leader__popup-label mt-20'>Номер телефона поручителя</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="+7(___) ___-__-__"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.guarantorPhone}
                    name = 'phone'
                    readOnly={false}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, guarantorPhone: event.target.value})
                    }}
                />

                <label htmlFor="" className='leader__popup-label mt-20'>Название товара</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' value={inform.product} className="leader__popup-input" onChange={(e) => setInform({...inform, product: e.target.value})}/>
                
                <label htmlFor="" className='leader__popup-label mt-20'>Дата платежа</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="__.__.____"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.nextDate}
                    name = 'payment-date'
                    readOnly={false}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, nextDate: event.target.value})
                    }}
                />
                
                <RangeElem val={inform.productPrice} lable={'Цена приобретения'} min={0} max={100000} step={500} onChangeFunc={(productPrice)=>setInform({...inform, productPrice: productPrice})}/>
                <RangeElem val={inform.installmentPeriod} lable={'Срок рассрочки'} min={0} max={24} step={1} onChangeFunc={(installmentPeriod)=>setInform({...inform, installmentPeriod: installmentPeriod})}/>
                <RangeElem val={inform.interestRate} lable={'Процентная ставка'} min={0} max={100} step={1} onChangeFunc={(interestRate)=>setInform({...inform, interestRate: interestRate})}/>
                <RangeElem val={inform.anInitialFee} lable={'Первоначальный взнос'} min={0} max={50000} step={100} onChangeFunc={(anInitialFee)=>setInform({...inform, anInitialFee: anInitialFee})}/>
            </div>
            <CalculatorBottom className='scroll-active' anInitialFee={inform.anInitialFee} monthlyPayment={Math.round((+inform.productPrice + +inform.productPrice/100*inform.interestRate - inform.anInitialFee) / ((inform.installmentPeriod > 0)? inform.installmentPeriod : 1))} totalSumm={Math.round(+inform.productPrice + +inform.productPrice/100*inform.interestRate)}/>
            <div className='error-block'>
                {error}
            </div>
            <div className='d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Отменить</div>
                <div className={`leader__popup-button fifty-button ${loading? 'none-click': ''}`} onClick={postData}>Добавить</div>
                {/* <button className='leader__popup-button fifty-button'>Добавить</button>                 */}
            </div>
        </div>
	)
}
export default AddClient;