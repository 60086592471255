import { createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
import axiosProxy from '../../../axiosProxy';
import { getDateFormatFunc } from '../../../services/clientHome';





export const fetchLeaderHomeClientsData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderHomeClientsData',
  async (dataObj={count:30, query:false, page:0, isScrolling: false}) => {
    let postData = {count: dataObj.count, page: dataObj.page}
    if(dataObj.query) postData.query = dataObj.query

    try {
      const response = await axiosProxy.get(`note/Client/get-client-list?`+ new URLSearchParams(postData));
      if(response.status < 400){
        return {list: response.data.clientsTbaleData, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);

export const fetchLeaderComplitedClientsData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderComplitedClientsData',
  async (dataObj={count:30, query:false, page:0, isScrolling: false}) => {
    let postData = {count: dataObj.count, page: dataObj.page}
    if(dataObj.query) postData.query = dataObj.query

    try {
      const response = await axiosProxy.get(`/note/Client/get-finish-client-list?`+ new URLSearchParams(postData));
      if(response.status < 400){
        return {list: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                    
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);

export const fetchLeaderClientPaymentHistoryData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderClientPaymentHistoryData',
  async (dataObj) => {


    try {
      const response = await axiosProxy.get(`/note/Client/get-client-payments?`+ new URLSearchParams(dataObj));
      if(response.status < 400){
        return {list: response.data.data};                 
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);

export const fetchLeaderHomeCheckClient = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderHomeCheckClient',
  async (dataObj) => {
    try {
      const response = await axiosProxy.get('note/Client/get-client?'+ new URLSearchParams(dataObj));
      if(response.status < 400){
        return {list: response.data};                    
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);

export const fetchLeaderConsolidatedReport = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderConsolidatedReport',
  async (range) => {
    let params = '';
    if(range.length!== 0){
      params = '?'+new URLSearchParams({
        from: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(range[0].toLocaleDateString())),
        to: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(range[1].toLocaleDateString()))
      })
    } 
    else params = '';

    try {
      const response = await axiosProxy.get('/note/Client/get-report'+params);
      if(response.status < 400){
        return {list: response.data};                    
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);
