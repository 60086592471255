import Calendar from 'react-calendar';
import "./index.scss";
import MaskedInput from './../MaskedInput/MaskedInput';

function Calend({date, checkDate, setCheckDate}){
    return (
        <div className='filters-full'>
            <div className='filters-full__scroll'>
                <div className={`filters-full__calendar-container`}>
                    {/* <Calendar onChange={setCheckDate} value={(typeof checkDate === )? checkDate: null} className={'not-range'}/> */}
                    <Calendar onChange={setCheckDate} value={(typeof checkDate === 'object')? checkDate: null} className={'not-range'}/>
                    {/* <Calendar value={selRange} onChange={onChange} inline showWeek /> */}
                    <div className='react-calendar__range d-flex justify-between'>
                        <div className='react-calendar__range-item start'>
                            <div className='react-calendar__range-label'>Дата платежа</div>
                            <div className='leader__popup-text-block'>{date}</div>
                        </div>
                        <div className='react-calendar__range-item end'>
                            <div className='react-calendar__range-label'>Дата отсрочки</div>
                            {/* <div className='leader__popup-text-block'>{(checkDate)? checkDate.toLocaleDateString(): ''}</div> */}
                            <MaskedInput
                                className={`leader__popup-text-block`}
                                mask="__.__.____"
                                replacement={{_ : /[0-9]/}}
                                showMask={true}
                                defaultValue={(checkDate !== null && typeof checkDate === 'object')? checkDate.toLocaleDateString(): checkDate}
                                name = 'payment-date'
                                readOnly={false}
                                placeholder=''
                                onChange={(event)=>{
                                    setCheckDate(event.target.value)
                                }}
                            />

                        </div>
                    </div>                    
                </div>
            </div>
            
        </div>
    )
}
export default Calend;
