import {useDispatch, useSelector} from 'react-redux';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy, {axiosProxyCatchPost} from '../../../../axiosProxy';
import { fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';
import { useState } from 'react';

const ConfirmDeferment = ({ unDisplay, query }) => {
    const dispatch = useDispatch()
    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const postData = async() =>{
        let requestData = {id: leaderHomeCheckClient.id}
        setLoading(true)

        axiosProxyCatchPost({
            url: '/note/Client/remove-deferment',
            data: requestData,                                
            resolved: (data)=>{
                if(data){
                    dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                    unDisplay()
                    setLoading(false)
                }
            },
            rejected: (error)=>{                
                if(error && error.data && typeof error.data === 'string') setError(error.data)
                else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
            }
        })
    }

	return (
        <div className="leader__popup-container deferment-payment">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title calendar'>Отсрочка платежа</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-confirmation-title'>Вы дествительно хотите отменить отсрочку клиента "{leaderHomeCheckClient.clientName}"?</div>
            <div className='error-block fs-10 mt-10'>
                {error}
            </div>
            <div className='filters-full__buttons d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Закрыть</div>
                <div className={`leader__popup-button fifty-button ${loading? 'none-click': ''}`} 
                onClick={postData}>Подтвердить</div>
            </div>
        </div>
	)
}
export default ConfirmDeferment;
