import Popup from '../Popup/Popup';
import './index.scss'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeaderPopupBackFunck } from '../../../redux/home/popup/popupSlice';
import { setAdminHomeCheckLeader, setAdminHomeLeaders, setAdminHomeLeadersEdit } from '../../../redux/admin/users/clientsSlice';
import { selectAdminHomeLeaders } from '../../../redux/admin/users/selectors';
import { fetchAdminHomeLeadersData } from '../../../redux/admin/users/asyncActions';
import axiosProxy, { axiosProxyCatchPost } from './../../../axiosProxy';
import { scrollingPagination } from '../../../services/clientHome';

const Home = ({setPopupType, query}) =>{
    const dispatch = useDispatch()

    const {adminHomeLeadersData, pageLast} = useSelector(selectAdminHomeLeaders)

    const [clientPaymentMenuActive, setClientPaymentMenuActive] = useState({id: 0, val: ''})
    const [page, setPage] = useState(0)

    useEffect(()=>{
        dispatch(fetchAdminHomeLeadersData())
    }, [])

    const paymentUser = async() =>{
        axiosProxyCatchPost({
            url: 'note/Admin/payment-user',
            data: {id: clientPaymentMenuActive.id, payment: clientPaymentMenuActive.val},
            resolved: (data)=>{                
                if(data) dispatch(setAdminHomeLeadersEdit({id: clientPaymentMenuActive.id, shutdownDate: data.nextDate}))                
            },
            rejected: (error)=>{                
                // if(error && error.data) setError(error.data)
                // else setError('Что-то пошло не так*')     
            
                console.log(error)
            }
        })

        setClientPaymentMenuActive({id:0, val:''})
    }
    const blockUser = async(id, blocking) =>{
        axiosProxyCatchPost({
            url: 'note/Admin/Block-user',
            data: {id},
            resolved: (data)=>{                
                if(data) dispatch(setAdminHomeLeadersEdit({id, blocking: !blocking}))              
            },
            rejected: (error)=>{                
                // if(error && error.data) setError(error.data)
                // else setError('Что-то пошло не так*')     
            
                console.log(error)
            }
        })
    }

    const addLeadersTableItem = (item) =>{
        const {id, name, phone, login, password, paymentDate, shutdownDate, blocking, paymentOverdue} = item;
        return(
            <div className={`admin__home-table-line-container ${(login === 'samh' || login === 'samh2')? 'd-none': ''}`} key={`admin__home-table-line-${id}-${paymentDate}-${name}`}>
                <div className="admin__home-table-line d-flex align-center justify-between">
                    {/* <div className="admin__home-table-line-item number">
                        {id}
                    </div> */}
                    <div className="admin__home-table-line-item name">
                        {name}
                    </div>
                    <div className="admin__home-table-line-item phone">
                        {phone}
                    </div>
                    <div className="admin__home-table-line-item login">
                        {login}
                    </div>
                    <div className="admin__home-table-line-item password">
                        {password}
                    </div>
                    <div className="admin__home-table-line-item date">
                        {paymentDate}
                    </div>
                    <div className={`admin__home-table-line-item date ${(paymentOverdue)? 'overdue':''}`}>
                        {shutdownDate}
                    </div>
                    <div className={`admin__home-table-line-button lock mr-20 ${blocking?'open':''}`} onClick={()=>blockUser(id, blocking)}/>
                    <div className={`admin__home-table-line-button-container mr-20`}>
                        <div className={`admin__home-table-line-button payment popup-click`} onClick={()=>setClientPaymentMenuActive((clientPaymentMenuActive.id === id)? {id: 0, val: ''}: {id: id, val: ''})}/>
                        
                        <div className={`admin__home-table-line-button-popup popup-click ${(clientPaymentMenuActive.id === id)? 'active': ''}`}>
                            <label htmlFor="" className='leader__popup-label popup-click'>Месяцев оплачено</label>
                            <input name='ClientPayment' autoComplete="off" type="number" placeholder='' value={clientPaymentMenuActive.val} className="leader__popup-input popup-click" onChange={(e)=>setClientPaymentMenuActive({id: id, val: e.target.value})}/>
                            <div className='admin__home-table-line-button-popup-save popup-click' onClick={paymentUser}>
                                Сохранить
                            </div>
                        </div>
                    </div>
                    <div className="admin__home-table-line-button edit"  onClick={()=>{
                        dispatch(setAdminHomeCheckLeader(item))
                        setPopupType('EditLeader')
                        LeaderPopupBackFunck(true, dispatch)
                    }}/>                  
                </div>
            </div>
        )
    }
    
    return(
        <div className="admin__home" onClick={(event)=>{
            if(clientPaymentMenuActive.id !== 0 && !event.target.classList.contains('popup-click'))
            setClientPaymentMenuActive({id: 0, val: ''})
        }}>
            <div className="admin__home-top d-flex justify-between align-center">
                <div className="admin__home-top-title">Пользователи</div>
                <div className="admin__home-top-button-container d-flex align-center add-client" onClick={()=>{
                    setPopupType('AddLeader')
                    LeaderPopupBackFunck(true, dispatch)
                }}>                  
                    <div className="admin__home-top-button-title">Добавить</div>
                    <div className="admin__home-top-button-icon"/>
                </div>
            </div>

            <div className="admin__home-table-container">
                <div className='admin__home-table-search-container align-center mr-30'>
                    <div className='admin__home-table-search-icon'/>
                    <input type="text" placeholder='Поиск' className='admin__home-table-search-input'/>                 
                </div>
                <div className='admin__home-table-line-container title'>
                    <div className="admin__home-table-line d-flex align-center justify-between">
                        {/* <div className="admin__home-table-line-item number">Id</div> */}
                        <div className="admin__home-table-line-item name">ФИО</div>
                        <div className="admin__home-table-line-item phone">Телефон</div>
                        <div className="admin__home-table-line-item login">Логин</div>
                        <div className="admin__home-table-line-item password">Пароль</div>
                        <div className="admin__home-table-line-item date">Дата оплаты</div>
                        <div className="admin__home-table-line-item date">Дата платежа</div>
                        <div className="admin__home-table-line-button mr-20"/>
                        <div className="admin__home-table-line-button mr-20"/>
                        <div className={`admin__home-table-line-button`}/>
                    </div>
                </div>

                <div className="admin__home-table">
                    {
                        adminHomeLeadersData.map(addLeadersTableItem)
                    }
                </div>
            </div>
        </div>
    )
}

export default Home;