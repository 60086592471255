import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchLeaderHomeCheckClient, fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';
import { setLeaderHomeCheckClientDataFiles, setLeaderHomeCheckClients, setLeaderHomeClientsDelete } from '../../../../redux/home/clients/clientsSlice';
import { CalculatorBottom} from '../Calculator/Calculator';
// import { postNotData } from '../../../../redux/actions/mineWarehouse';
import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy, {axiosProxyCatchPost} from './../../../../axiosProxy';
import { choiceUserFilesFunc, deleteUserFilesFunc, getDateFormatFunc} from './../../../../services/clientHome';
import MaskedInput from './../../../MaskedInput/MaskedInput';
// import Popup from '../Popup';
import { proxyAddress } from '../../../..';


const ClientDetail = ({unDisplay, popupBack, popupTypeEdit, query, completed=false}) => {
    const dispatch = useDispatch()


    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)
    const [error, setError] = useState('')
    const [typeIsEdit, setTypeIsEdit] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inform, setInform] = useState({
        clientName: '',
        clientPhone: '',   
        guarantorName: '',
        guarantorPhone: '',
        product: '',
        address: '',
        dataFiles: [],
        comment: ''
    })

    const [editedInform, setEditedInform] = useState({})
    const [dataFiles, setDataFiles] = useState([])
    const [dataFilesUrl, setDataFilesUrl] = useState([])
    const [deletedFiles, setDeletedFiles] = useState([])

    useEffect(()=>{
        if(leaderHomeCheckClient.id) dispatch(fetchLeaderHomeCheckClient({id: leaderHomeCheckClient.id}))
        setIsEdited(false)
        setTypeIsEdit(false)
        setError('')
        setDataFiles([])
        setDataFilesUrl([])
    }, [popupBack])


    useEffect(()=>{
       setInform(leaderHomeCheckClient)
    }, [leaderHomeCheckClient, typeIsEdit])

    const postData = async() =>{
        if(inform.clientName && inform.clientPhone.length === 17 && inform.clientPhone.indexOf('_') === -1 && inform.guarantorName && inform.guarantorPhone.length === 17 && inform.guarantorPhone.indexOf('_') === -1 && inform.product && inform.address){
            setError('')
            setLoading(true)
            let requestData = {
                id: leaderHomeCheckClient.id,
                name: inform.clientName,
                phone: inform.clientPhone,   
                guarantorName: inform.guarantorName,
                guarantorPhone: inform.guarantorPhone,
                product: inform.product,
                address: inform.address,
                // nextDate: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(inform.nextDate)),
                comment: inform.comment
            }

            axiosProxyCatchPost({
                url: 'note/Client/edit-client',
                data: requestData,
                resolved: (data)=>{                
                    if(data){
                        dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
            
                        if(dataFiles.length !== 0){
                            let formData = new FormData();
                            for(let item of dataFiles) formData.append('Files', item)
                            formData.append('Guid', leaderHomeCheckClient.guid)                           
                            axiosProxyCatchPost({
                                url: '/note/Client/set-files',
                                data: formData,                                
                                isFormData: true,
                                resolved: (data)=>{},
                                rejected: (error)=>{                
                                    if(error && error.data && typeof error.data === 'string') setError(error.data)
                                    else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                                    else setError('Что-то пошло не так*')     
                                }
                            })
                        }
                        if(deletedFiles.length !== 0){
                            axiosProxyCatchPost({
                                url: '/note/Client/delete-files',
                                data: {guid: leaderHomeCheckClient.guid, files: deletedFiles},                                
                                resolved: (data)=>{},
                                rejected: (error)=>{                
                                    if(error && error.data && typeof error.data === 'string') setError(error.data)
                                    else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                                    else setError('Что-то пошло не так*')    
                                }
                            })
                        }
                        setLoading(false)
                        unDisplay()
                    }           
                },
                rejected: (error)=>{                
                    if(error && error.data && typeof error.data === 'string') setError(error.data)
                    else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')   
                }
            })
        }
        else if(inform.clientPhone.indexOf('_') === -1 || inform.guarantorPhone.indexOf('_') === -1){
            if(inform.clientPhone.indexOf('_') !== -1) setError('Неправильный формат телефона клиента "+7(999) 999-99-99"')
            else if(inform.guarantorPhone.indexOf('_') !== -1) setError('Неправильный формат телефона поручителя "+7(999) 999-99-99"')
        }
        else{
            setError('Необходимо корректно заполнить все поля*')
        }
    }

    const deleteClient = async() =>{
        axiosProxyCatchPost({
            url: 'note/Client/delete-client',
            data: {id: leaderHomeCheckClient.id},
            resolved: (data)=>{                
                if(data){
                    dispatch(setLeaderHomeCheckClients({ id: 0}))
                    dispatch(setLeaderHomeClientsDelete(leaderHomeCheckClient.id))
                    unDisplay()
                }           
            },
            rejected: (error)=>{                
                if(error && error.data && typeof error.data === 'string') setError(error.data)
                else if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
            }
        })
        
    }

    const imageDelete = (type, key, item) =>{
        if(type === 'oldFile'){
            setInform({...inform, dataFiles: inform.dataFiles.filter(link=> item !== link)})
            setDeletedFiles([...deletedFiles, item])
            setIsEdited(true)
        }
        else{
            deleteUserFilesFunc(key, item, (files)=>setDataFiles(files), dataFiles, (urls)=>setDataFilesUrl(urls), dataFilesUrl)
        }
    }
    
    const imageClick = (key) =>{
        if(!typeIsEdit){
            dispatch(setLeaderHomeCheckClientDataFiles({index: key, files: [...inform.dataFiles.map(item => proxyAddress + item), ...dataFilesUrl]}))
            popupTypeEdit('Galery')
        }
    }

    const addImageBlock = (item, key, type) =>{
        return(
            <div className={`leader__popup-download-file-img-container d-flex justify-center align-center ${typeIsEdit? 'edit-image': ''}`} key={key} onClick={()=>imageClick(key)}>                
                {
                    (type === 'oldFile')? (
                        <div className={`leader__popup-download-file-img-delete-icon ${!typeIsEdit? 'd-none': ''}`} onClick={()=>imageDelete('oldFile', key, item)}/>
                    ):(
                        <div className={`leader__popup-download-file-img-delete-icon ${!typeIsEdit? 'd-none': ''}`} onClick={()=>imageDelete('newFile', key, item)}/>
                    )
                }
                <img className='leader__popup-download-file-img' src={((type === 'oldFile')? proxyAddress: '') + item} alt="" />
            </div>
        )
    }

	return (
        <div className="leader__popup-container scroll-active">
            <div className='d-flex justify-between align-center mb-10'>
                <div className='leader__popup-title'>{(!typeIsEdit)? 'Информация о клиенте': 'Редактирование клиента'}</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-container-scroll client-detail'>
                <label htmlFor="" className='leader__popup-label'>ФИО клиента</label>
                <input name='EnvelopeName'  readOnly={!typeIsEdit} autoComplete="off" type="text" placeholder='' value={inform.clientName} className="leader__popup-input" onChange={(e) => {
                    setInform({...inform, clientName: e.target.value})
                    setEditedInform({...editedInform, clientName: e.target.value})
                    setIsEdited(true)
                }}/>
                
                <label htmlFor="" className='leader__popup-label mt-20'>Адрес клиента</label>
                <input name='EnvelopeName'  readOnly={!typeIsEdit} autoComplete="off" type="text" placeholder='' value={inform.address} className="leader__popup-input" onChange={(e) => {
                    setInform({...inform, address: e.target.value})
                    setEditedInform({...editedInform, address: e.target.value})
                    setIsEdited(true)
                }}/>
                
                <label htmlFor="" className='leader__popup-label mt-20'>Номер телефона клиента</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="+7(___) ___-__-__"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.clientPhone}
                    name = 'phone'
                    readOnly={!typeIsEdit}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, clientPhone: event.target.value})
                        setEditedInform({...editedInform, clientPhone: event.target.value})
                        setIsEdited(true)
                    }}
                />

                <label htmlFor="" className={`leader__popup-label mt-20 ${(!typeIsEdit && inform.dataFiles.length === 0)? 'd-none': ''}`}>Файлы</label>
                <div className={`d-flex justufy-between flex-wrap leader__popup-download-file-container ${(!typeIsEdit && inform.dataFiles.length === 0)? 'd-none': ''}`}>                 
                    {
                        inform.dataFiles.map((item, key)=>addImageBlock(item, key, 'oldFile'))
                    }
                    {
                        (typeIsEdit)? dataFilesUrl.map((item, key)=>addImageBlock(item, key, 'newFile')): null
                    }

                    {
                        (typeIsEdit)? (
                            <>
                                <input type="file" name='files' accept="" className='leader__popup-download-file-input d-none' id='leader__create-user-add-file-input' onChange={(event)=> {choiceUserFilesFunc(event, (file)=>setDataFiles([...dataFiles, file]), (url)=>setDataFilesUrl([...dataFilesUrl, url]))}}/>
                                <label className='leader__popup-download-file-label d-flex align-centr' htmlFor='leader__create-user-add-file-input'/>
                            </>
                        ): null
                    }
                </div>

                <label htmlFor="" className='leader__popup-label mt-20'>Комментарий</label>
                <textarea name='EnvelopeName' placeholder='' readOnly={!typeIsEdit} value={inform.comment? inform.comment: ''} className="leader__popup-textarea" onChange={(e) => {
                    setInform({...inform, comment: e.target.value})
                    setEditedInform({...editedInform, comment: e.target.value})
                    setIsEdited(true)
                }}/>


                <label htmlFor="" className={`leader__popup-label ${(!typeIsEdit && inform.dataFiles.length === 0)? 'mt-20': 'mt-10'}`}>ФИО поручителя</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder=''  readOnly={!typeIsEdit} value={inform.guarantorName} className="leader__popup-input" onChange={(e) => setInform({...inform, guarantorName: e.target.value})}/>
                <label htmlFor="" className='leader__popup-label mt-20'>Номер телефона поручителя</label>
                <MaskedInput
                    className={`leader__popup-input`}
                    mask="+7(___) ___-__-__"
                    replacement={{_ : /[0-9]/}}
                    showMask={true}
                    defaultValue={inform.guarantorPhone}
                    name = 'phone'
                    readOnly={!typeIsEdit}
                    placeholder=''
                    onChange={(event)=>{
                        setInform({...inform, guarantorPhone: event.target.value})
                        setEditedInform({...editedInform, guarantorPhone: event.target.value})
                        setIsEdited(true)
                    }}
                />

                <label htmlFor="" className='leader__popup-label mt-20'>Название товара</label>
                <input name='EnvelopeName' autoComplete="off" type="text" placeholder='' readOnly={!typeIsEdit} value={inform.product} className="leader__popup-input" onChange={(e) => {
                     setInform({...inform, product: e.target.value})
                     setEditedInform({...editedInform, product: e.target.value})
                     setIsEdited(true)
                }}/>


                <label htmlFor="" className='leader__popup-label text-block mt-20'>{(completed? 'Дата начала рассрочки': 'Дата платежа')}</label>
                <div name='EnvelopeName' className="leader__popup-text-block mb-20">{leaderHomeCheckClient.nextDate}</div>
                <div className='d-flex justify-between'>
                    <div className='leader__popup-input-fifty'>
                        <label htmlFor="" className='leader__popup-label text-block'>Срок рассрочки:</label>
                        <div name='EnvelopeName' className="leader__popup-text-block mb-20">{leaderHomeCheckClient.installmentPeriod}</div>
                    </div>
                    <div className='leader__popup-input-fifty'>
                        <label htmlFor="" className='leader__popup-label text-block'>Процентная ставка:</label>
                        <div name='EnvelopeName' className="leader__popup-text-block mb-20">{leaderHomeCheckClient.interestRate}%</div>
                    </div>
                </div>
                {
                    (completed)?(
                        <>
                            <label htmlFor="" className='leader__popup-label text-block'>Цена покупки:</label>
                            <div name='EnvelopeName' className="leader__popup-text-block">{leaderHomeCheckClient.productPrice}</div>
                        </>
                    ):(
                        <div className='d-flex justify-between'>
                            <div className='leader__popup-input-fifty'>
                                <label htmlFor="" className='leader__popup-label text-block'>Цена покупки:</label>
                                <div name='EnvelopeName' className="leader__popup-text-block">{leaderHomeCheckClient.productPrice}</div>
                            </div>
                            <div className='leader__popup-input-fifty'>
                                <label htmlFor="" className='leader__popup-label text-block'>Остаток суммы:</label>
                                <div name='EnvelopeName' className="leader__popup-text-block">{leaderHomeCheckClient.reamainderSumm}</div>
                            </div>
                        </div>
                    )
                }
                
                
                <CalculatorBottom className='mt-20' anInitialFee={leaderHomeCheckClient.anInitialFree} monthlyPayment={leaderHomeCheckClient.monthlyPayment} totalSumm={leaderHomeCheckClient.totalSumm}/>
            </div>
            <div className={`error-block ${completed? 'd-none': ''}`}>
                {error}
            </div>
            <div className='d-flex justify-between'>
                {
                    (completed)?null:
                    (typeIsEdit)?(
                        <>
                            <div className='leader__popup-button delete fifty-button' onClick={()=>setTypeIsEdit(false)}>Отменить</div>
                            <div className={`leader__popup-button fifty-button ${(isEdited || dataFiles.length && !loading)? '': 'none-click'}`} onClick={postData}>Сохранить</div>
                        </>
                    ):(
                        <>
                            <div className='leader__popup-button delete fifty-button' onClick={deleteClient}>Удалить</div>
                            <div className='leader__popup-button fifty-button' onClick={()=>setTypeIsEdit(true)}>Редактировать</div>
                        </>
                    )
                }
            </div>
        </div>
	)
}

export default ClientDetail;