import { createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
import axiosProxy from '../../../axiosProxy';





export const fetchAdminHomeLeadersData = createAsyncThunk(
  'AdminHomeLeaders/fetchAdminHomeLeadersData',
  async () => {
    try {
      const response = await axiosProxy.get('note/Admin/get-user-list');
      if(response.status < 400){
        return {list: response.data.adminHomeLedersData};                    
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);
